* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --container-margin-x: 5%;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 10%;
  overflow-x: hidden !important;
}

body {
  color: #fff;
  background-color: #151621;
  font-family: Outfit;
  overflow-x: hidden !important;
}

::selection {
  color: #8088ff;
  background: #434464b8;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.container {
  padding: 0 var(--container-margin-x);
  max-width: 1600px;
  margin: 0 auto;
}

h2 {
  font-size: 2.1rem;
  font-weight: 400;
}

h3 {
  font-size: 1.5rem;
  font-weight: 400;
}

nav {
  height: 2.5rem;
  flex-flow: row;
  justify-content: space-between;
  margin-top: 25px;
  display: flex;
}

#logo {
  width: 50px;
}

.loaded nav #logo img {
  height: 100%;
  position: relative;
  transform: translate(0);
}

nav #logo img {
  height: 100px;
  z-index: 101;
  -webkit-filter: drop-shadow(0 0 20px #000000b3);
  filter: drop-shadow(0 0 20px #000000b3);
  transition: all 1s;
  position: relative;
  transform: translate(calc(45vw - 50%), 40vh);
}

nav #links {
  width: 375px;
}

nav ul {
  height: 100%;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  display: flex;
}

#links a {
  color: #858585;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
  transition: all .5s;
  position: relative;
}

#links a:hover {
  color: #fff;
}

#links a:after {
  content: "";
  width: 0;
  height: 1px;
  background: linear-gradient(-90deg, #833ab4, #fd1d1d, #fcb045);
  border-radius: 1px;
  transition: all .2s;
  position: absolute;
  bottom: -5px;
  left: 10%;
}

#links a:hover:after {
  width: 80%;
}

#links .phone-navbar {
  display: none;
}

@media screen and (width <= 600px) {
  nav #links {
    width: auto;
  }

  #links li:not(:first-child) {
    visibility: visible;
    pointer-events: none;
    opacity: 0;
    transition: all .2s;
    transform: translateY(-20px);
  }

  #links ul {
    flex-direction: column;
    align-items: flex-end;
    position: relative;
  }

  #links .active li:not(:first-child) {
    visibility: visible;
    opacity: 1;
    pointer-events: visible;
    margin-bottom: .7rem;
    transform: translateY(0);
  }

  #links .phone-navbar {
    height: 2.5rem;
    align-items: center;
    font-size: 1.25rem;
    display: flex;
  }

  #links .active .phone-navbar i {
    background: #833ab4 linear-gradient(225deg, #833ab4, #fd1d1d, #fcb045) 0 0 / 120%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #0000;
    margin: auto;
  }

  #links .phone-navbar:hover:after {
    display: none;
  }
}

#landing-page {
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
  margin: 100px 0;
}

#memojis-slideshow {
  width: 10rem;
  height: 10rem;
  margin: 0 auto 60px;
  position: relative;
}

#memojis-slideshow .gradient {
  width: 100%;
  height: 100%;
  -webkit-filter: blur(2.2rem);
  filter: blur(2.2rem);
  background: linear-gradient(45deg, #833ab4, #fd1d1d, #fcb045);
}

.memoji-container {
  justify-content: center;
  align-self: center;
  display: flex;
  position: absolute;
  top: 5%;
  left: 0;
}

#memojis-img {
  width: 100%;
  height: 100%;
  -webkit-filter: drop-shadow(0 0 15px #000000b3);
  filter: drop-shadow(0 0 15px #000000b3);
  opacity: 1;
  transition: all .5s;
}

.hide-memoji {
  opacity: 0 !important;
  transform: translateX(-50%)scale(.5) !important;
}

.show-memoji {
  opacity: 0 !important;
  transition: transform !important;
  transform: translateX(50%)scale(.5) !important;
}

#landing-page h1 {
  margin-top: 10px;
  font-size: 3rem;
}

.gradient-text {
  background: #833ab4 linear-gradient(225deg, #833ab4, #fd1d1d, #fcb045) 0 0 / 120%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (width <= 550px) {
  #landing-page h1 {
    height: 6rem;
  }

  .gradient-text {
    display: block;
  }
}

.personnal-network {
  width: 2.5rem;
  height: 2.5rem;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 2.25rem;
  justify-content: center;
  align-items: center;
  margin: 35px auto 0;
  padding: 5px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.personnal-network:hover {
  background-color: #fff;
}

.personnal-network:hover i {
  background: #833ab4 linear-gradient(225deg, #833ab4, #fd1d1d, #fcb045);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #0000;
}

.personnal-network .tooltip {
  -webkit-text-fill-color: white;
  top: -65%;
}

.networks-links {
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  gap: 25px;
  margin: 0 auto;
  display: flex;
}

section {
  background-color: #1b1d2d80;
  border-radius: 25px;
  margin-bottom: 10%;
  padding: 20px 7.5%;
  position: relative;
  box-shadow: 0 0 30px #00000040;
}

section.transparent {
  box-shadow: none;
  background: none;
  padding: 0;
}

section.transparent > .title {
  margin-bottom: 60px;
}

section .title {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 30px;
  display: block;
  position: relative;
}

section .title:after {
  content: "";
  width: 80%;
  height: 2px;
  background: linear-gradient(-90deg, #833ab4, #fd1d1d, #fcb045);
  border-radius: 1px;
  position: absolute;
  bottom: -30%;
  left: 10%;
}

#about:before {
  content: "";
  width: 30vw;
  height: 30vw;
  -webkit-filter: blur(6vw);
  filter: blur(6vw);
  z-index: -1;
  background: linear-gradient(15deg, #833ab4, #fd1d1d, #fcb045);
  border-radius: 50%;
  position: absolute;
  top: 10%;
  left: -25%;
}

#about {
  width: 100%;
}

#about-flex {
  justify-content: space-around;
  align-items: center;
  padding-bottom: 2.5%;
  display: flex;
}

#personal-infos {
  width: 12rem;
}

#personal-infos img {
  width: 100%;
  border-radius: 50%;
  margin: 0 auto 1rem;
  box-shadow: 0 0 25px #0000004d;
}

#personal-infos h4 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 5px auto;
  font-size: 1.4em;
  font-weight: 400;
  display: block;
}

#about-me {
  width: 70%;
  padding: 0 10px;
  font-size: 1.2em;
  font-weight: 200;
  line-height: 1.5em;
}

#skills:after {
  content: "";
  width: 30vw;
  height: 30vw;
  -webkit-filter: blur(6vw);
  filter: blur(6vw);
  z-index: -1;
  background-color: #833ab4;
  border-radius: 50%;
  position: absolute;
  bottom: 40%;
  right: -25%;
}

#skills-flex {
  justify-content: space-between;
  display: flex;
}

#skills-flex section {
  width: 47.5%;
  margin-bottom: 0;
  padding: 30px 4%;
}

.progress-bar {
  width: 100%;
  height: 40px;
  background-color: #1e2135;
  border-radius: 20px;
  margin: 20px 0;
  position: relative;
}

.progress-bar .progress {
  height: 80%;
  width: var(--progress);
  position: absolute;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
}

.progress-bar .progress-fill {
  width: 100%;
  -moz-column-gap: 20px;
  height: 100%;
  text-align: center;
  z-index: 2;
  white-space: nowrap;
  background: linear-gradient(-90deg, #833ab4, #fd1d1d, #fcb045);
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 5px #0000004d;
}

.progress-bar .progress-blur {
  height: 100%;
  width: 100%;
  z-index: 1;
  -webkit-filter: blur(5px);
  filter: blur(5px);
  background: linear-gradient(-90deg, #833ab4, #fd1d1d, #fcb045);
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.progress-fill i {
  font-size: 1.3em;
}

.tooltip-parent {
  position: relative;
}

.tooltip {
  opacity: 0;
  width: auto;
  color: #fff;
  text-align: center;
  background-color: #35396480;
  border-radius: 5px;
  padding: 3px 8px;
  font-family: Outfit;
  font-size: .9em;
  transition: opacity .2s;
  position: absolute;
  top: -100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tooltip:after {
  content: "";
  border: 5px solid #0000;
  border-top-color: #35396480;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-parent:hover .tooltip {
  opacity: 1;
}

#projects-flex {
  flex-wrap: wrap;
  place-content: space-around;
  display: flex;
}

.project-card {
  width: 25rem;
  height: 20.5rem;
  perspective: 1200px;
  margin: 0 0 3rem;
  padding: 0;
  position: relative;
}

.card-inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  border-radius: inherit;
  background-color: #1b1d2d;
  padding: 0;
  transition: transform 1s, width .5s, height .5s, top .5s, left .5s;
  position: relative;
  top: 0%;
  left: 0%;
  box-shadow: 0 0 30px #00000040;
}

.card-inner.is-flipped {
  width: 115%;
  height: 115%;
  transition: transform 1s, width .5s 1s, height .5s 1s, top .5s 1s, left .5s 1s;
  top: -7.5%;
  left: -7.5%;
  transform: rotateY(180deg);
}

.card-face {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: inherit;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.card-face-front {
  cursor: pointer;
  text-align: center;
  padding: 10px 30px 20px;
  transform: translateZ(1px);
}

.card-inner .card-face-front:hover {
  background-color: #36385580;
}

.card-face-front img {
  max-width: 100%;
  height: 14rem;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0 0 10px 6px #0c0d1470;
}

.card-face-front .title {
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all .2s;
  position: relative;
  top: 10px;
}

.card-face-front:hover .title {
  top: 0;
}

.card-face-front .title:after {
  width: 0;
  transition: all .2s .1s;
}

.card-face-front:hover .title:after {
  width: 80%;
}

.card-face-back {
  flex-direction: column;
  padding: 20px 35px;
  display: flex;
  transform: rotateY(180deg)translateZ(1px);
}

.card-face-back p {
  margin-bottom: 25px;
  font-size: 1.3em;
  font-weight: 300;
  line-height: 1.3em;
  overflow: hidden;
}

.card-face-back .more-infos {
  color: #fff;
  cursor: pointer;
  background: #292b4180;
  border: none;
  border-radius: 10px;
  margin: auto;
  padding: 10px 20px;
  font-family: Outfit;
  font-size: 1.5em;
  text-decoration: none;
}

.card-face-back .more-infos:hover {
  background: #3a3d5c80;
}

.flip-btn {
  width: 50px;
  height: 50px;
  cursor: pointer;
  background: #292b4180;
  border-radius: 0 0 75%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.flip-btn:hover {
  background: #3a3d5c80;
}

footer {
  height: 120px;
  position: relative;
}

footer .separation {
  width: 100%;
  height: 2px;
  background: linear-gradient(-90deg, #3fa2ff, #833ab4, #fd1d1d, #fcb045);
  position: absolute;
  top: 1px;
  left: 0;
}

footer .separation-blur {
  width: 100%;
  height: 4px;
  -webkit-filter: blur(3px);
  filter: blur(3px);
  background: linear-gradient(-90deg, #3fa2ff, #833ab4, #fd1d1d, #fcb045);
  position: absolute;
  top: 0;
  left: 0;
}

footer h4, a {
  color: #5c5f87;
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.5rem;
}

footer .container {
  height: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

footer .light {
  color: #5c5f8769;
}

footer a {
  text-decoration: none;
}

footer a:hover {
  color: #7c7fb1;
}

footer .social-network {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.social-network iframe {
  width: 120px;
  margin: 5px;
}

.modal-container {
  width: 100vw;
  height: 100vh;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  background: #00000065;
  justify-content: center;
  align-items: center;
  padding: 20%;
  transition: all .2s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-container.visible {
  opacity: 1;
  pointer-events: all;
}

.modal-container .close-btn {
  width: 50px;
  height: 50px;
  color: #1b1d2d;
  background: #fff;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  display: flex;
  position: absolute;
  top: 5%;
  right: 5%;
}

.video-modal {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.video-modal:after {
  content: "";
  padding-top: 56.25%;
  display: block;
}

.video-modal iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#loader {
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 100;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.loader-left, .loader-right {
  width: 50%;
  height: 120%;
  -webkit-backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #191a27e6;
  transition: all 1s;
  position: absolute;
  top: 0;
}

.loader-left {
  left: 0;
}

.loaded .loader-left {
  transform: translateX(-100%);
}

.loader-right {
  right: 0;
}

.loaded .loader-right {
  transform: translateX(100%);
}

.loaded #loader {
  pointer-events: none;
}

.dynamic {
  opacity: 0;
  transition: all 1s;
}

.dynamic.scrolled {
  opacity: 1;
}

.dynamic.scrolled.slide-left {
  animation: 1s slide-left;
}

.dynamic.scrolled.slide-right {
  animation: 1s slide-right;
}

.dynamic.scrolled.pop {
  animation: 1s pop;
}

.dynamic.scrolled .progress {
  animation: 1.5s ease-in-out fill-bar;
}

@keyframes slide-left {
  0% {
    transform: translateX(-150px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(150px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes pop {
  0% {
    transform: scale(.7);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes fill-bar {
  0% {
    width: 20%;
  }
}

.go-top {
  width: 2.5rem;
  height: 2.5rem;
  color: #fff;
  z-index: 10;
  border: 2px solid #fff;
  border-radius: 1.25rem;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.go-top.hidden {
  opacity: 0;
  pointer-events: none;
}

.go-top:hover {
  background-color: #fff;
}

.go-top:hover i {
  background: #833ab4 linear-gradient(225deg, #833ab4, #fd1d1d, #fcb045);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #0000;
}

@media screen and (width <= 850px) {
  #about:after {
    width: 80vw;
    height: 80vw;
    top: 20%;
    left: -50%;
  }

  #about-flex {
    flex-direction: column;
  }

  #personal-infos {
    width: 100%;
    height: 150px;
    justify-content: center;
    margin: 25px 0;
    display: flex;
  }

  #personal-infos img {
    height: 100%;
    width: auto;
    margin: 0;
  }

  #personal-infos #infos {
    height: 100%;
    width: auto;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    display: flex;
  }

  #personal-infos h4 {
    width: auto;
    margin-left: 20px;
  }

  #about-me {
    width: 100%;
  }

  #skills-flex {
    flex-direction: column;
    align-items: center;
  }

  #skills-flex section {
    width: 100%;
    margin-bottom: 50px;
  }

  #skills:after {
    width: 50vw;
    height: 50vw;
    -webkit-filter: blur(10vw);
    filter: blur(10vw);
    border-radius: 50%;
    top: 15%;
    right: -40%;
  }
}

/*# sourceMappingURL=index.35046345.css.map */
